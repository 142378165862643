/*
  DMP COMPONENT: DAYTIME
*/

@import '~react-day-picker/lib/style.css';

$dmp-daytime: '#{$dmp}-daytime';

.#{$dmp-daytime} {
  display: flex;
  align-items: center;

  .DayPickerInput input,
  .react-time-picker__wrapper {
    padding: 3px 5px;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: 1px solid $black10;
    height: 30px;
  }

  .DayPickerInput input {
    padding-left: 8px;
    width: 90px;
  }

  .at {
    margin-left: 6px;
    margin-right: 6px;
  }

  &.disabled {
    color: $black40;
    input {
      color: $black40;
    }
    .DayPickerInput,
    .react-time-picker,
    .react-time-picker--disabled {
      background-color: $black3 !important;
    }
  }
}

.DayPicker {
  @extend .font_sans;
  font-size: 13px;
}

.DayPicker-wrapper {
  padding-bottom: 0;
}

.DayPicker-Months {
  padding: 14px;
}

.DayPicker-NavBar {
  .DayPicker-NavButton {
    &.DayPicker-NavButton--prev {
      top: 18px;
      left: 15px;
    }

    &.DayPicker-NavButton--next {
      top: 18px;
      right: 15px;
    }
  }
}

.DayPicker-Caption {
  text-align: center;
  padding: 6px;
  margin: 0;

  > div {
    font-size: 14px;
  }
}

.DayPicker-Month {
  width: 214px;
  height: 164px;
  margin: 0;
}

.DayPicker-Weekday {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.DayPicker-Day {
  color: #000;
  font-weight: 600;
  padding: 2px;

  .DayPicker-Day-Inside {
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;

    border-radius: 50%;
    background: transparent;
  }
}

.DayPicker-Day--outside {
  color: rgba(0, 0, 0, 0.25);
}

.DayPickerInput {
  position: relative;
}

.DayPickerInput-Overlay {
  top: 5px;
  border-radius: 2px;
  border: 1px solid #b8b8be;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

// Adjustments to enable rendering the datepicker at the top of the input
.DayPickerInput-OverlayWrapper-Top {
  position: absolute;
  bottom: 35px;

  .DayPickerInput-Overlay {
    position: initial;
    margin-top: 0;
  }
}

// Selected day styling
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 50%;
  background-color: #ffffff;
  color: #854ad3;

  .DayPicker-Day-Inside {
    border: 2px solid rgba(133, 74, 211, 1);
    line-height: 24px;
  }
}
// Selected day :hover styling
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #ffffff;

  .DayPicker-Day-Inside {
    background-color: rgba(217, 189, 255, 0.5);
  }
}

// Day hover styling
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #ffffff;

  .DayPicker-Day-Inside {
    background-color: rgba(217, 189, 255, 0.5);
  }
}

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba(133, 74, 211, 0.25) !important;
  color: #854ad3;
}
.InputFromTo .DayPicker-Day--disabled {
  color: #b8b8be;
}

.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day--start {
  color: #ffffff !important;
  background-color: #854ad3 !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
  color: #ffffff !important;
  background-color: #854ad3 !important;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
  width: 250px;
}
